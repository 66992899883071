import React, { FC, Fragment, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { runInAction } from 'mobx';
import { inject, observer, useLocalObservable } from 'mobx-react';
import { Box, Drawer, List, Typography, Divider, ListItem, ListItemButton } from '@mui/material';
import { SwycLogo } from '@assets';
import { ChildSurveyStore, UIStore } from '@stores';
import { ChildSurveyService } from '@services';
import { ChildSurveyModel } from '@models';
import { SurveyQuestions, useNotification } from '@shared';
import { useStyles } from './Survey.styles';

type Props = {
  childSurveyStore?: ChildSurveyStore;
};

const Survey: FC<Props> = ({ childSurveyStore }: Props) => {
  const classes = useStyles();
  const params = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const { survey } = childSurveyStore;
  const observable = useLocalObservable(() => ({
    orderIndex: 0,
    errorMessage: '',
    navigatorDisplay: 'none',
  }));

  useEffect(() => {
    getSurvey();    
    setNavigator();
    return () => {
      childSurveyStore.setSurvey(null);
      childSurveyStore.setSurveySection(null);      
    };
  }, []);

  const getSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.getChildSurvey(params?.id, false).subscribe({
      next: (response: ChildSurveyModel) => {
        const surveySection = response?.surveySections[0];
        childSurveyStore.setSurvey(response);
        childSurveyStore.setSurveySection(surveySection);
        runInAction(() => {
          observable.orderIndex = surveySection.orderIndex;
        });
        UIStore.hideLoader();
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        runInAction(() => {
            observable.errorMessage = error.message;
          });        
      },
    });
  };

  const showBack = (): boolean => {
    return observable.orderIndex !== 1;
  };

  const getLastOrderIndex = (): number => {
    return survey?.surveySections[survey?.surveySections?.length - 1]?.orderIndex;
  };

  const handleNext = (): void => {
    if (observable.orderIndex === getLastOrderIndex()) {
      submitSurvey();
      return;
    }
    const orderIndex = observable.orderIndex + 1;
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const goBack = (): void => {
    const orderIndex = observable.orderIndex - 1;
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const setSurveyQuestions = (orderIndex: number) => {
    runInAction(() => {
      observable.orderIndex = orderIndex;
    });
    const findSurveySection = survey?.surveySections?.find(section => section.orderIndex === orderIndex);
    childSurveyStore.setSurveySection(findSurveySection);
  };

  const submitSurvey = () => {
    UIStore.showLoader();
    ChildSurveyService.addPublicSurvey(survey).subscribe({
      next: (response: number) => {
        UIStore.hideLoader();
        navigate('/thanks', { replace: true });
      },
      error: (error: AxiosError) => {
        UIStore.hideLoader();
        notification.error(error?.message);
      },
    });
  };

  const renderComponentLabel = () => {
    return survey?.surveySections?.find(x => x?.orderIndex === observable.orderIndex)?.name;
  };

  const setNavigator = () =>{
    
    var isMobile = 
    navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         //|| navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i);
    observable.navigatorDisplay = isMobile ? 'none' : 'flex'

    
  }

  return (
    <div style={{ padding: 30, backgroundColor: 'white', height: '100%' }}>
      {observable.errorMessage ? (
        <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
          {observable.errorMessage}
        </Typography>
      ) : (
        <>
          <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            {`Survey - ${survey?.childName}`}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <img src={SwycLogo} className={classes.img} alt="SWYC"></img>
            <div>
              <Typography>{`SWYC ${survey?.surveyName}`}</Typography>
              <Typography>{survey?.surveyDescription}</Typography>
              <Typography>{survey?.version}</Typography>
            </div>
          </Box>
          <Box sx={{ display: 'flex', height: 'calc(100vh - 240px)', marginTop: '30px' }}>
            <Drawer sx={{display: observable.navigatorDisplay}} className={classes.sideBar} variant="permanent" anchor="left">
              <Divider />
              <ListItem className={classes.title}>Section Navigation</ListItem>
              <List>
                {survey?.surveySections?.map(section => (
                  <Fragment key={section.surveySectionId}>
                    <ListItem
                      key={section.surveySectionId}
                      disablePadding
                      onClick={() => setSurveyQuestions(section.orderIndex)}
                      sx={{
                        backgroundColor:
                          observable.orderIndex === section.orderIndex ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                      }}
                    >
                      <ListItemButton>{section.name}</ListItemButton>
                    </ListItem>
                    <Divider style={{ color: '#80808061' }} />
                  </Fragment>
                ))}
              </List>
            </Drawer>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }} className={classes.sideBox}>
              <Typography className={classes.title}>{renderComponentLabel()}</Typography>
              <SurveyQuestions handleNext={handleNext} goBack={goBack} showBack={showBack()} />
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default inject('childSurveyStore')(observer(Survey));
