import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  boldText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginRight: '29px',
  },
  para: {
    whiteSpace: 'pre-wrap',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontSize: '16px',
    marginTop: '2rem',
  },
  btn: {
    width: '200px',
  },
  demoConatiner: {
    marginTop: '2rem',
    width: '50%',
    margin: 'auto',
  },
  radioLabel: {
    fontWeight: 'bold',
    color: '#333333',
  },
  radioContainer: {
    margin: '1rem 0px',
  },
  selectBox: {
    '& .MuiFormLabel-root': {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.palette.grey[500],
      top: '-9px',
    },
    '&.MuiInputBase-root ': {
      marginTop: '3px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-3px',
      '& legend': {
        display: 'none',
      },
    },
  },  
  errorText: {
    color: theme.palette.error.main,
  },
  inputzipcode: {
    width: '200px',
    margin: '16px 0 4px',
    marginTop:'1rem'
  }
}));
