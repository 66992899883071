import React, { FC } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { MobxReactForm, Field } from 'mobx-react-form';
import { Typography, Box, FormLabel, FormControlLabel, Button, RadioGroup, Radio, FormHelperText } from '@mui/material';
import { ChildrenStore, CustomDropDown, FormInputControl, UIStore, getFormValidation, useNotification } from '@shared';
import { ChildrenService, LocalStorageService } from '@services';
import { IDropdownOptions } from '@interfaces';
import { EthnicityOptions, GenderOptions, RaceOptions, ColumbiaResidentOptions } from './Options';
import { DemographicFields } from '../Fields';
import { useStyles } from './DemographicsForm.styles';

type Props = {
  childrenStore?: ChildrenStore;
  caregiverName?: string;
  caregiverEmail?: string;
};

const form: MobxReactForm = getFormValidation(DemographicFields);

const DemographicsForm: FC<Props> = ({ childrenStore, caregiverName, caregiverEmail }: Props) => {
  const classes = useStyles();
  const notification = useNotification();
  const navigate = useNavigate();

  const getField = (key: string): Field => {
    return form.$(key);
  };

  const handleChange = (value, field: string) => {
    getField(field).set(value);
  };

  const onSubmit = () => {
    form.submit({
      onSuccess: () => {
        const { ethnicityId, raceId, genderId, columbiaResident, zipcode } = form.values();
        const req = {
          childId: childrenStore?.child?.id,
          ethnicityId: Number(ethnicityId),
          raceId: Number(raceId),
          genderId: Number(genderId),
          columbiaResident: Number(columbiaResident),
          zipcode: zipcode,
        };
        UIStore.showLoader();
        ChildrenService.childConsent(req).subscribe({
          next: (response: boolean) => {
            UIStore.hideLoader();
            form.reset();
            const redirectUrl = LocalStorageService.getRedirectUrl();
            LocalStorageService.removeRedirectUrl();
            navigate(redirectUrl, { replace: true });
          },
          error: (error: AxiosError) => {
            UIStore.hideLoader();
            notification.error(error?.message);
          },
        });
      },
      onError: error => {},
    });
  };

  const renderOptions = (options: IDropdownOptions[]) => {
    return options.map((item, index) => (
      <FormControlLabel key={index} value={item.value} control={<Radio />} label={item.label} />
    ));
  };

  return (
    <>
      <Box className={classes.demoConatiner}>
        <Typography>Please fill in the following information for this child</Typography>
        <div className={classes.radioContainer}>
          <FormLabel component="legend" className={classes.radioLabel}>
            Race
          </FormLabel>
          <RadioGroup value={form.$('raceId').value} onChange={e => handleChange(e.target.value, 'raceId')}>
            {renderOptions(RaceOptions)}
          </RadioGroup>
        </div>
        <div className={classes.radioContainer}>
          <FormLabel component="legend" className={classes.radioLabel}>
            Ethnicity
          </FormLabel>
          <RadioGroup value={form.$('ethnicityId').value} onChange={e => handleChange(e.target.value, 'ethnicityId')}>
            {renderOptions(EthnicityOptions)}
          </RadioGroup>
        </div>
        <CustomDropDown
          value={form.$('genderId').value}
          label={form.$('genderId').label}
          placeholder={form.$('genderId').placeholder}
          field={form.$('genderId')}
          options={GenderOptions}
          onValueChange={value => handleChange(value, 'genderId')}
          className={classes.selectBox}
          form={form}
        />
        <div className={classes.radioContainer}>
          <FormLabel className={classes.radioLabel}>This child is a Columbia Resident</FormLabel>
          <RadioGroup row value={form.$('columbiaResident').value} onChange={e => handleChange(e.target.value, 'columbiaResident')}>
            {renderOptions(ColumbiaResidentOptions)}
          </RadioGroup>
          {Boolean(form?.submitted) && form.$('columbiaResident').hasError && (
            <FormHelperText className={classes.errorText}>Columbia residency is required.</FormHelperText>
          )}
        </div>
        <div>
        <FormLabel className={classes.radioLabel}>Zip Code</FormLabel>
        <FormInputControl
            field={form.$('zipcode')}
            styleClasses={{ inputControl: classes.inputzipcode }}
            onValueChange={value => handleChange(value, 'zipcode')}
            showLabel={false}
            form={form}
          />
        </div>
        <div className={classes.btnContainer}>
          <Button variant="contained" className={classes.btn} onClick={() => onSubmit()}>
            Submit
          </Button>
        </div>
      </Box>
    </>
  );
};

export default inject('childrenStore')(observer(DemographicsForm));
