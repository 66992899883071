export const DemographicFields = [
    {
        name: 'houseHoldSize',
        label: 'How many people are in your household?',
        placeholder: 'Select',
        rules: 'required',
        value: '',
    },
    {
        name: 'hasHouseHoldIncomeOver2092PerMonth',
        rules: 'required',
        value: '',
    },
    {
        name: 'countyName',
        label: 'County',
        placeholder: 'Select',
        rules: 'required',
        value: '',
    },
    {
        name: 'countyOtherName',
        placeholder: 'Other County',
        label: 'Other County',
        rules: 'string',
        value: '',
    },
    {
        name: 'ethnicityId',
        label: 'Ethnicity',
        rules: 'required',
        value: '',
    },
    {
        name: 'raceId',
        label: 'Race',
        rules: 'required',
        value: '',
    },
    {
        name: 'genderId',
        label: 'Gender',
        placeholder: 'Select Gender',
        rules: 'required',
        value: '',
    },
    {
        name: 'ageGroupId',
        label: 'Age Group',
        rules: 'required',
        value: '',
    },
    {
        name: 'columbiaResident',
        label: 'Columbia Resident',
        rules: 'required',
        value: '',
    },
    {
        name: 'zipcode',
        label: 'Zip Code',
        rules: 'required',
        value: '',
    }
]

export const ConsentFormFields = [
    {
        name: 'information',
        label: '',
        rules: 'required',
        value: '',
    },
    {
        name: 'becc',
        label: '',
        rules: 'required',
        value: '',
    },
]