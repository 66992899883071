export const ConsentFields = [
  {
    name: 'information',
    label: '',
    rules: 'required',
    value: '',
  },
  {
    name: 'survey',
    label: '',
    rules: 'required',
    value: '',
  },
];

export const DemographicFields = [
  {
    name: 'ethnicityId',
    label: 'Ethnicity',
    rules: 'required',
    value: '',
  },
  {
    name: 'raceId',
    label: 'Race',
    rules: 'required',
    value: '',
  },
  {
    name: 'genderId',
    label: 'Gender',
    placeholder: 'Select Gender',
    rules: 'required',
    value: '',
  },
  {
    name: 'columbiaResident',
    label: 'Columbia Resident',
    placeholder: 'Select Columbia Residency',
    rules: 'required',
    value: '',
  },
  {
    name: 'zipcode',
    label: 'Zip Code',
    placeholder: 'Zip Code',
    rules: 'required',
    value: '',
  },
];
